import React, {useContext, useMemo, useRef} from 'react';
import {v4 as uuid} from 'uuid';
import clsx from 'clsx';
import merge from 'lodash-es/merge';

import {AppContext} from './app-context';
import {arcPath, scale, segmentHeight} from '../utils/math';
import {mdToSVGTextSpan, wordWrap} from '../utils/format';
import {DEFAULT_OPTIONS} from '../services/options';

import {arcText} from './styles/arc-text.module.scss';

const MIN_ARC = 6;
const MAX_ARC = 12;
const ARC_INCREMENTS = 10;

type Props = {
  children: string;
  className?: string;
  id?: string;
  width?: number;
  isCondensed?: boolean;
  isPrintable?: boolean;
  isMarkdown?: boolean;
  options?: {
    arcHeight?: number;
    fontSize?: number;
    letterSpacing?: number;
  };
};

const ArcText = ({
  children,
  className,
  width = 1000,
  options,
  isCondensed,
  isMarkdown = true,
  isPrintable,
  id
}: Props) => {
  const ref = useRef<SVGSVGElement>(null);
  const {userOptions} = useContext(AppContext);
  const {fontSize, arcHeight, letterSpacing} = merge(
    {},
    DEFAULT_OPTIONS,
    userOptions,
    options
  );
  const spacing = (letterSpacing / 100) * (fontSize / 2);
  const arcDegrees = scale(arcHeight / ARC_INCREMENTS, MIN_ARC, MAX_ARC);
  const height = useMemo(() => segmentHeight(width, arcDegrees), [
    width,
    arcDegrees
  ]);
  const path = useMemo(() => arcPath(width, height, fontSize), [
    width,
    height,
    fontSize
  ]);
  const lineHeight = isCondensed ? 2 * fontSize : 3 * fontSize;
  // Assumes average character width is ~50% of font size (obviously wrong for monospace font)
  const maxChars = Math.floor(width / ((fontSize / 1.95) + spacing));
  const wrappedLines = wordWrap(children, maxChars);

  const renderLines = (lines: string[], pathId: string) => {
    const totalHeight = height + ((lines.length - 1) * lineHeight);
    return (
      <svg
        key={pathId}
        ref={ref}
        overflow="visible"
        className={clsx(className, arcText)}
        height={totalHeight}
        width={width}
        style={{
          height: 'auto',
          marginBottom: isPrintable ?
            `-${totalHeight - lineHeight}px` :
            `-${height - (2 * lineHeight)}px`,
          fontSize,
          letterSpacing: `${spacing}px`
        }}
        viewBox={`0 0 ${width} ${totalHeight}`}
      >
        <defs>
          <path d={path} id={`text-boundary-${pathId}`}/>
        </defs>
        {lines.map((line: string, i: number) => (
          <text
            key={`${pathId}-${line}`}
            transform={`translate(0, ${i * lineHeight})`}
          >
            <textPath
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: (isMarkdown ? mdToSVGTextSpan(line) : line) || '&nbsp;'
              }}
              href={`#text-boundary-${pathId}`}
              startOffset="50%"
              textAnchor="middle"
            />
          </text>
        ))}
      </svg>
    );
  };

  if (isPrintable) {
    return <>{wrappedLines.map(l => renderLines([l], id ?? uuid()))}</>;
  }

  return renderLines(wrappedLines, id ?? uuid());
};

ArcText.getTextFromDOM = (node: SVGElement) => {
  if (node) {
    const text = Array.from(node.children)
      .filter(n => n.tagName === 'text')
      .map(n => {
        const html = n.children[0]?.innerHTML; // Get innerHTML of textpaths
        return html.replaceAll('<br><br>', '<br>').replaceAll('<br>', '\n');
      })
      .join('');
    return text;
  }

  return '';
};

export default ArcText;
