export const mdToSVGTextSpan = (text: string) => {
  const bold = /\*\*(.*?)\*\*/gm;
  const sup = /<sup>(.*?)<\/sup>/gm;

  let spans = text.replace(bold, '<tspan style="font-weight: bold">$1</tspan>');

  spans = spans.replace(
    sup,
    '<tspan baseline-shift="super" style="font-size: 0.5em">$1</tspan>'
  );

  return spans;
};

export const formatDate = (date: Date | number) => {
  // eslint-disable-next-line new-cap
  return Intl.DateTimeFormat('en-US', {
    weekday: 'long',
    month: 'long',
    day: 'numeric'
  }).format(date);
};

export const wordWrap = (text: string, maxChars: number) => {
  if (!text) {
    return [''];
  }

  const wrappedLines = [''];

  let lineNumber = 0;

  // Start with one line per newline
  for (const [l, line] of text.split('\n').entries()) {
    if (l > 0) {
      wrappedLines[lineNumber] = '\n';
    }

    // Wrap text in roughly equal lines, breaking on word boundaries
    // TODO: Break long "words" (e.g., URLs)
    for (const [i, word] of line.split(' ').entries()) {
      if (word === '' && i === 0) {
        break;
      }

      const lineLength = wrappedLines[lineNumber].length;
      // Move to next line if length would be exceeded
      if (lineLength > 0 && lineLength + word.length >= maxChars) {
        wrappedLines[(lineNumber += 1)] = '';
      }

      // Append word to line (but don't start a line with punctuation)
      if (lineNumber > 0 && i === 0 && /^[.,:!?)]/.test(word)) {
        wrappedLines[lineNumber - 1] += `${i === 0 ? '' : ' '}${word}`;
      } else {
        wrappedLines[lineNumber] += `${i === 0 ? '' : ' '}${word}`;
      }
    }

    lineNumber += 1;
  }

  return wrappedLines;
};
