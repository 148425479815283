type Coordinate = [number, number];

export const dist = (a: Coordinate, b: Coordinate) =>
  Math.sqrt(((a[0] - b[0]) ** 2) + ((a[1] - b[1]) ** 2));

// Calculate the arc path that intersects start, apex, and end points
export const circlePath = (
  start: Coordinate,
  apex: Coordinate,
  end: Coordinate
) => {
  const A = dist(end, apex);
  const B = dist(apex, start);
  const C = dist(start, end);

  const angle = Math.acos(((A ** 2) + (B ** 2) - (C ** 2)) / (2 * A * B));

  // Calc radius of circle
  const K = 0.5 * A * B * Math.sin(angle);
  let r = (A * B * C) / 4 / K;
  r = Math.round(r * 1000) / 1000;

  // Large arc flag
  const laf = Number(Math.PI / 2 > angle);

  // Sweep flag
  const saf = Number(
    ((end[0] - start[0]) * (apex[1] - start[1])) -
      ((end[1] - start[1]) * (apex[0] - start[0])) <
      0
  );

  return ['M', start, 'A', r, r, 0, laf, saf, end].join(' ');
};

export const scale = (value: number, bottom: number, top: number) =>
  bottom + ((top - bottom) * value);

export const segmentHeight = (chordLength: number, degrees: number) => {
  const radians = (degrees * Math.PI) / 180;
  const opposingAngles = (Math.PI - radians) / 2;
  const radius = (chordLength * Math.sin(radians)) / Math.sin(opposingAngles);
  return radius - (0.5 * Math.sqrt((4 * (radius ^ 2)) - (degrees ^ 2)));
};

export const arcPath = (width: number, height: number, fontSize: number) => {
  const totalHeight = height + fontSize;
  return circlePath(
    [0, totalHeight],
    [width / 2, fontSize],
    [width, totalHeight]
  );
};
